html {
  font-size: 18px;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 1rem;
  background-color: var(--brand-color);
}

.content {
  background-color: white;
}

.nav > li {
  display: block;
}

p {
  font-size: 1.1rem;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: bold;
  color: var(--brand-color);
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a {
  text-decoration: none;
  font-weight: bold;
  color: var(--brand-color) !important;
}

h4 {
  font-size: 1.3rem;
}

ul.tags {
  list-style-type: none;
  margin: 0.5rem 0;
  padding: 0;
  overflow: hidden;
  display: inline-block;
}

li.tag {
  font-size: 0.75rem;
  float: left;
  background-color: var(--brand-color);
  display: block;
  color: white;
  text-align: center;
  padding: 0.25em 0.5rem;
  margin: 0.25em 0.5em 0.25em 0rem;
  border-radius: 0.5rem;
  text-decoration: none;
}

table {
  margin: 1rem 0;
}

th {
  border-bottom: solid 1px black;
  padding: 0.25em 0.5rem;
}

td {
  padding: 0.25em 0.5rem;
}

tr:nth-child(even) {
  background-color: #e0e0e0;
}

.container {
  margin-bottom: 3rem;
  flex: 1;
}

.container h1,
.container h2,
.container h3 {
  margin-top: 1.5rem;
}

.container h4,
.container h5,
.container h6 {
  margin-top: 1rem;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  margin: 1rem 0;
}

.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.draft {
  background: #ffaaaa;
}

.warning {
  position: absolute;
  top: 0.25rem;
  right: 0.75rem;
  z-index: 999;
  color:#ff9900;
  text-align: right;
  text-shadow: #ffffff 1px 0 25px;
  -webkit-text-stroke: 1px black;
}

.warning .fas {
  padding: 3px;
}

.frontpage .warning {
  font-size: 3rem;
}

.category-list .warning {
  font-size: 2rem;
}

.motto {
  margin: 2rem 0 1.5rem 0;
  padding: 1rem;
  background: #f0f0f0;
  border: solid 1px #e0e0e0;
  text-align: center;
  font-size: 1.5rem;
//  font-weight: bold;
}

.notice {
  margin: 2rem 0 2rem 0;
  padding: 1rem;
  background: #f0f0f0;
  border: solid 1px #e0e0e0;
  text-align: center;
  font-size: 1.2rem;
  font-weight: bold;
}

.gallery {
  margin: 1.5rem 0 ;
}

.arrow {
  color: var(--brand-color);
  font-size: 0.5rem;
  vertical-align: middle;
  line-height: 1.5;
}

.footer {
  padding: 1.5rem 0;
  color: white;
  background: var(--brand-color);
  font-size: 0.9rem;
}

.footer a {
  text-decoration: none;
}

.footer ul.nav {
  display: table;
}

.footer ul.nav li {
  padding: 0.75rem;
}

.footer .buildinfo {
  font-size: 0.9rem;
}

.center {
  text-align: center;
}

.col img {
  max-width: 100%;
  width: 100%;
  height: auto;
}

.preview {
  box-shadow: 2px 2px 6px #aaaaaa;
  position: relative;
}

.article-description {
  padding: 0 1rem 1rem 1rem;
}

.article-title {
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: bold;
  color: var(--brand-color);
  margin: 0.25rem 0;
}

.frontpage .article-title {
  height: 5.4rem;
}

.article-title a {
  text-decoration: none;
}

.article-category {
  font-size: 1rem;
  font-weight: bold;
  color: var(--brand-color);
}

.article-info {
  font-size: 0.75rem;
}

.post-info {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.post-info-bottom {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
}

.article-image {
  margin-bottom: 0.25rem;
  border-radius: 1rem;
  width: 100%;
  height: auto;
}

.article-image img {
  width: 100%;
  height: auto;
  aspect-ratio: 2.11;
  object-fit: cover;
}

.article-image.about img {
  aspect-ratio: 1.3;
}

// ----- Category list -------------------------------------------------------

aside.category-list {
  padding: 1rem;
  background: #f0f0f0;
  border: solid 1px #e0e0e0;
}

.category-list .article-title {
  font-size: 1rem;
  line-height: 1.5rem;
  height: 4.5rem;
  font-weight: bold;
  color: var(--brand-color);
  margin: 0.25rem 0;
}

.category-list .article-description {
  padding: 0 0.5rem 0.5rem 0.5rem;
}

.category-list .article-image img {
  aspect-ratio: 2.11;
}

// ---------------------------------------------------------------------------

.gallery .description {
  margin: 0.1rem 0 1rem 0;
}

.image-description {
  text-align: center;
  font-size: 0.9rem;
  font-style: italic;
  font-weight: bold;
  margin: 0.1rem 0 0.1rem 0;
}

#leaflet_post {
  height: 33vh;
  margin: 1rem 0;
}

#leaflet_all {
  height: 50vh;
}

img.article {
  max-width: 100%;
  padding: 1rem;
}

blockquote {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C" "\201D";
}

blockquote:before {
  color: #ccc;
  font-size: 4rem;
  line-height: 0.1rem;
  margin-right: 0.25rem;
  vertical-align: -0.4rem;
}

blockquote p {
  display: inline;
}

#navbar-main {
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.1rem;
}

.card {
  margin-bottom: 1.5rem;
}

.hero {
  margin-bottom: 1rem;
  position: relative;
}

.hero img {
  width: 100%;
  height: 30vh;
  object-fit: cover;
}

.hero .imagecredits {
  background-color: rgba(255,255,255,0.5);
  padding: 0.5em 1em;
  text-align: right;
  font-size: 0.75em;
  bottom: 1em;
  right: 1em;
  position: absolute;
  border-radius: 0.5rem;
}

.hero-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  color: #ffffff;
  text-align: center;
}

.overlay-content {
  width: 50%;
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#slogan {
  color: #ffffff;
  font-weight: bold;
  font-size: 2rem;
  text-shadow: #000000 1px 0 5px;
}

.error.container {
  max-width: 600px;
  text-align: center;
}

.error h1 {
  margin: 4rem 0;
  font-size: 10rem;
  background: var(--brand-color);
  color: #ffffff;
}

.error h2 {
  font-size: 4rem;
}

.error p {
  font-size: 1.5rem;
}

.flashmessage {
  text-align: center;
  background: #ff6666;
  padding: 2rem;
}

.hazard-background {
  text-align: center;
  color: white;
  padding: 2rem;
  background-image: repeating-linear-gradient(-55deg,
      #000,
      #000 20px,
      #ffb101 20px,
      #ffb101 40px);
}

.hazard-border {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 1rem;
  border: 1rem solid pink;
  border-image: repeating-linear-gradient(-55deg,
      #000,
      #000 20px,
      #ffb101 20px,
      #ffb101 40px) 10;
}

.hazard-border h1 {
  color: red;
  font-weight: bold;
}

.separator {
  border-bottom: solid 1px #dddddd;
}

#back-to-top {
  z-index: 999;
  position: fixed;
  right: 1rem;
  bottom: 14rem;
  display: none;

  a {
    outline: none;
  }

  i {
    font-size: 2rem;
  }
}

ol.carousel-indicators {
  bottom: -40px;
  width: 100%;
  left: 0;
  margin: 1rem 0;
}

.carousel-indicators > li {
  border: solid 1px var(--brand-color) !important;
  width: 4px !important;
  height: 16px !important;
  border-radius: 0;
}

.carousel-indicators > li.active {
  background: var(--brand-color);
  border: solid 1px var(--brand-color);
  width: 4px !important;
  height: 16px !important;
  margin-bottom: 1px;
  border-radius: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-size: 100%, 100%;
  border-radius: 50%;
  background-image: none;
}

.carousel-control-next-icon:after
{
  content: '>';
  font-size: 55px;
  color: var(--brand-color);
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  color: var(--brand-color);
}

#chartcontainer {
}

#chartcontainer img {
  display: block;
  max-width: 100%;
  margin: auto;
}

.comment {
  margin-top: 1rem;
}