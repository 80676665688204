/* ---------- Color Module Styles ----------- */

$theme-colors: (
  "primary":    #0073c6,
  "secondary":  #6c757d,
  "success":    #198754,
  "info":       #0dcaf0,
  "warning":    #ffc107,
  "danger":     #dc3545,
  "light":      #f8f9fa,
  "dark":       #212529
);

html {
  --brand-color: #0073c6;
}

body {
  color: #3b3b3b;
  background: #ffffff;
}

#navbar-top {
  background-color: var(--brand-color);
}

#navbar-main {
  background-color: var(--brand-color) !important;
}

.btn-primary {
  background-color: var(--brand-color) !important;
  border-color: var(--brand-color) !important;
  
}

a,
.link {
  color: var(--brand-color);
}

a:hover,
a:focus,
.link:hover,
.link:focus {
  color: var(--brand-color);
}

a:active,
.link:active {
  color: var(--brand-color);
}

.footer,
.footer a {
  color: white;
}

.red {
  color: #ff0000;
}